import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppConfig } from '@config/app.config';
import { AppScripts } from '@plugins/scripts/app-scripts';
import { AppModule } from './app/app.module';


// Enable production mode when running prod env
if (AppConfig.config.STAGE === 'production') {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => {
        AppScripts.init();
        //Set global
        (window as any).AppConfig = AppConfig.config;
    })
    .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
    });
