import { Component } from '@angular/core';
import { AppConfig } from '@config/app.config';

@Component({
    selector: 'oops',
    templateUrl: './oops.component.html',
    styleUrls: ['./oops.component.scss']
})
export class OopsComponent {
    public gotoMain(): void {
        location.href = AppConfig.config.origins.APP_URL;
    }
}
