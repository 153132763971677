export class Constants {
    public static URLS = {
        DEFAULT: '/api/v1/b',
        STATIC: '/api/v1/s'
    };
    public static KEYS = {
        BACKSPACE: 'Backspace',
        DELETE: 'Delete',
        ENTER: 'Enter',
        HOME: 'Home',
        ESCAPE: 'Escape',
        PAGE_DOWN: 'PageDown',
        PAGE_UP: 'PageUp',
        LEFT: 'ArrowLeft',
        RIGHT: 'ArrowRight',
        UP: 'ArrowUp',
        DOWN: 'ArrowDown'
    };
}

export enum State {
    Active = 0,
    Deleted = 1,
    Archived = 2
}
