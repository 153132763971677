import { Injectable } from '@angular/core';
import { AppConfigurationClient, GetConfigurationSettingResponse } from '@azure/app-configuration';
import { AppConfig } from '@config/app.config';

export interface IAzureAppConfiguration {
    label: string;
    key: string;
}

export class AzureAppConfigurations {
    public static readonly CampaignManagerMaintenance: IAzureAppConfiguration = {
        label: 'CampaignManager',
        key: 'CampaignManagerMaintenance'
    };

    public static readonly B2ClientRouting: IAzureAppConfiguration = {
        label: 'B2',
        key: 'B2_ClientRerouting'
    };
}

@Injectable({ providedIn: 'root' })
export class AzureAppConfigFeatureService {
    private configClient: AppConfigurationClient;

    constructor() {
        if (AppConfig.config.AZURE_APP_CONFIG_FEATURES_CONNECTION_STRING) {
            this.configClient = new AppConfigurationClient(
                AppConfig.config.AZURE_APP_CONFIG_FEATURES_CONNECTION_STRING
            );
        }
    }

    public async getConfigurationSetting(
        config: IAzureAppConfiguration,
        defaultValueOnDevEnv?: boolean
    ): Promise<boolean> {
        if (!this.configClient) {
            return defaultValueOnDevEnv || false;
        }

        const setting: GetConfigurationSettingResponse = await this.configClient.getConfigurationSetting({
            label: config.label,
            key: `.appconfig.featureflag/${config.key}`
        });

        return JSON.parse(setting.value).enabled;
    }
}
