import { Injectable } from '@angular/core';
import { PermissionRole } from '@shared/models/permissions.model';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    private _userRole$ = new BehaviorSubject<PermissionRole>(PermissionRole.PERMISSION_ROLE_NONE);

    userCanCreateCreativeSet$: Observable<boolean> = this._userRole$.asObservable().pipe(
        map(role => {
            // roles that are allowed to create creative sets
            return (
                role === PermissionRole.PERMISSION_ROLE_ADMIN ||
                role === PermissionRole.PERMISSION_ROLE_USER ||
                role === PermissionRole.PERMISSION_ROLE_DESIGNER ||
                role === PermissionRole.PERMISSION_ROLE_EXTERNAL_USER ||
                role === PermissionRole.PERMISSION_ROLE_EXTERNAL_DESIGNER
            );
        })
    );

    userCanDeleteCreativeSet$: Observable<boolean> = this.userCanCreateCreativeSet$;
    userCanRenameCreativeSet$: Observable<boolean> = this.userCanCreateCreativeSet$;

    userCanMoveCreativeSetOrFolder$: Observable<boolean> = this._userRole$.asObservable().pipe(
        map(role => {
            // roles that are not allowed to move creative sets or folders
            return !(
                role === PermissionRole.PERMISSION_ROLE_TEXT_EDITOR ||
                role === PermissionRole.PERMISSION_ROLE_TEXT_EDITOR_EXTENDED ||
                role === PermissionRole.PERMISSION_ROLE_PUBLISHER ||
                role === PermissionRole.PERMISSION_ROLE_ANALYZER ||
                role === PermissionRole.PERMISSION_ROLE_TEXT_EDITOR_WITH_ANALYZER ||
                role === PermissionRole.PERMISSION_ROLE_EXTERNAL_DESIGNER ||
                role === PermissionRole.PERMISSION_ROLE_PUBLISHER_EXTENDED
            );
        })
    );

    userCanDuplicateCreativeSetOrFolder$: Observable<boolean> = this._userRole$.asObservable().pipe(
        map(role => {
            // roles that are not allowed to duplicate creative sets or folders
            return !(
                role === PermissionRole.PERMISSION_ROLE_TEXT_EDITOR ||
                role === PermissionRole.PERMISSION_ROLE_PUBLISHER ||
                role === PermissionRole.PERMISSION_ROLE_ANALYZER ||
                role === PermissionRole.PERMISSION_ROLE_PUBLISHER_EXTENDED
            );
        })
    );

    setUserRole(role: PermissionRole): void {
        this._userRole$.next(role);
    }
}
