import { Injectable } from '@angular/core';
import { AppConfig } from '@config/app.config';
import { SessionService } from '@core/services/internal/session.service';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { HeaderConstants } from '@shared/constants/header-constants';
import { AuthService } from '@auth0/auth0-angular';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SignalRHubService {
    constructor(
        private sessionService: SessionService,
        private authService: AuthService
        ) {}

    public createConnection(url: string): HubConnection {
        const headers = this.prepareHeaders();
        const logLevel = this.determineLogLevel();

        return new HubConnectionBuilder()
            .withUrl(url, { accessTokenFactory: () => firstValueFrom(this.authService.getAccessTokenSilently()), headers })
            .withAutomaticReconnect()
            .configureLogging(logLevel)
            .build();
    }

    private prepareHeaders(): Record<string, string> {
        return {
            [HeaderConstants.BfBrandId]: this.sessionService?.user?.brand?.id ?? ''
        };
    }

    private determineLogLevel(): LogLevel {
        return AppConfig.config.STAGE === 'production' ? LogLevel.None : LogLevel.Debug;
    }
}
