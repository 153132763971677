import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { config } from '@config/environment.dev';
import { SentinelService } from '@bannerflow/sentinel';
import { catchError, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

interface TrackingResult {
    accounts: Account[];
}

interface Account {
    brands: Brand[];
}

interface Brand {
    slug: string,
}

export const brandRedirectGuard: CanActivateFn = (route, state) => {
    const router = inject(Router);
    const httpClient = inject(HttpClient);
    const sentinel = inject(SentinelService);
    const accountSlug = route.paramMap.get('accountSlug');

    return httpClient.get<TrackingResult>(`${config.origins.ACCOUNT_ACCESS_URL}/account/${accountSlug}/tracking`).pipe(
        switchMap(trackingResult => {
            if (trackingResult && trackingResult.accounts.length > 0 && trackingResult.accounts[0].brands.length > 0) {
                const brandSlug = trackingResult.accounts[0].brands[0].slug;
                router.navigate([`/${accountSlug}/${brandSlug}/creative-sets`]).then(_ => true);
            }
            return of(true);
        }),
        catchError(error => {
            sentinel.error(`Failed to get default brand for account with slug: ${accountSlug}`, error);
            return of(false);
        })
    );
};
