<div
    class="content"
    [ngClass]="{ 'full-page': isFullPage }">
    <div
        *ngIf="isFullPage"
        class="logo">
        <ui-logo [small]="true"></ui-logo>
    </div>
    <div class="title"><h1>We are sorry.</h1></div>
    <div class="text">
        <p>This link is not working. Please check again</p>
        <p class="second-ph">
            If you need any assistance,<br />please contact
            <a href="mailto:support@bannerflow.com">support&#64;bannerflow.com</a>
        </p>
        <p class="third-ph">The Bannerflow Team</p>
    </div>
</div>
