import { Injectable } from '@angular/core';
import { SessionService } from '@core/services/internal/session.service';
import { ApiService } from '../api.service';
import { AppConfig } from '@config/app.config';

@Injectable({ providedIn: 'root' })
export class InfoService {
    constructor(
        private apiService: ApiService,
        private sessionService: SessionService
    ) {}

    /**
     * This method is used for exposing BF_INFO model from backend and should be compatible
     * with old BF frontend, due to both uses the same GTM and Intercom variables.
     * @see wiki https://wikiflow.atlassian.net/wiki/spaces/DEV/pages/3672473604/BF+INFO
     * @param info
     */
    public async initBFInfoGlobalVariable(): Promise<void> {
        if (!this.sessionService.user) {
            throw new Error('User is not available');
        }

        const accountSlug = this.sessionService.user.account.slug;
        const brandSlug = this.sessionService.user.brand.slug;
        const info = await this.apiService
            .get(`${AppConfig.config.origins.ACCOUNT_ACCESS_URL}/account/${accountSlug}/tracking`, { anonymous: true })
            .catch(error => {
                const message = 'Could not get tracking info, some GTM and Intercom variables will be undefined!';
                console.error(message, error);
            });

        if (!info) {
            return;
        }

        // making it backwards compatible
        info.user.accounts = info.accounts;
        info.user.currentAccount = info.accounts.find((account: any) => account.slug === accountSlug);
        info.user.currentBrand = info.user.currentAccount.brands.find((brand: any) => brand.slug === brandSlug);
        info.user.isEmployee = info.user.employee === 3;
        info.user.title = this.sessionService.user.title;

        // https://github.com/nordicfactory/BannerFlow/blob/972fac1a36755d2c35818c3e2455b2cf9cdc1e37/BannerFlow/BannerFlow/Content/js/controllers/MainController.js#L35C5-L44
        for (const plan of info.user.currentAccount.plans) {
            if (plan.planCode.substr(0, 3) === 'bf-') {
                info.user.currentAccount.plan = plan;
                break;
            }
        }
        // sets global variable as readonly
        (window as any).BF_INFO = Object.freeze(info);
    }
}
