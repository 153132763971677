import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SidebarService {
    private _isExpanded: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public isExpanded(): Observable<boolean> {
        return this._isExpanded.asObservable();
    }

    public toggle(): void {
        const currentValue = this._isExpanded.getValue();
        this._isExpanded.next(!currentValue);
    }
}
