<div class="headerContent">
    <div
        class="headerContent__back"
        (click)="backClick()"
        *ngIf="isBackNavigationVisible()">
        <ui-icon icon="arrow-left-a"></ui-icon>&nbsp;
        {{ getBackText() }}
    </div>
    <div
        class="headerContent__back"
        *ngIf="showBrandPicker">
        <headerContentBrandPicker></headerContentBrandPicker>
    </div>
    <div
        class="headerContent__actions"
        *ngIf="sessionService.isLoggedIn()">
        <environment-picker *ngIf="isSandbox"></environment-picker>
        <div class="headerContent__actionsIcons">
            <div
                id="interaction-announcement"
                class="headerContent__actionsIcon">
                <ui-svg-icon
                    class="announcement"
                    icon="announcement"
                    fill="var(--ui-color-primary)"
                    (click)="openIntercomNews()"></ui-svg-icon>
            </div>
            <div
                class="headerContent__actionsIcon"
                *ngIf="isWelcomeMessageEnabled()"
                (click)="toggleWelcomePanel()"
                [ngClass]="{
                    'headerContent__actionsIcon--welcomeOpen': welcomePanelVisible,
                    'headerContent__actionsIcon--welcomeClosed': !welcomePanelVisible
                }">
                <ui-icon
                    icon="expand-solid"
                    *ngIf="!welcomePanelVisible"></ui-icon>
                <ui-icon
                    icon="collapse"
                    *ngIf="welcomePanelVisible"></ui-icon>
            </div>
            <div
                id="interaction-help-center"
                #helpMenu
                class="headerContent__actionsIcon"
                [uiDropdownTarget]="helpMenuDropDownMenu"
                *ngIf="!isPublicShowcase">
                <i
                    aria-hidden="true"
                    class="bf-icon bf-icon-help-tooltip">
                    <ui-dropdown
                        #helpMenuDropDownMenu
                        [positions]="[
                            {
                                originX: 'end',
                                originY: 'bottom',
                                overlayX: 'end',
                                overlayY: 'top',
                                offsetY: -5
                            }
                        ]">
                        <ui-dropdown-item (click)="gotoSupport()">Knowledge base</ui-dropdown-item>
                        <ui-dropdown-item (click)="Intercom('showNewMessage')">Chat with us</ui-dropdown-item>
                    </ui-dropdown>
                </i>
            </div>

            <div
                id="interaction-settings-menu"
                class="headerContent__actionsIcon"
                #settingsMenu
                [uiDropdownTarget]="settingsDropDownMenu"
                *ngIf="hasSettingsFeature">
                <i
                    aria-hidden="true"
                    class="bf-icon bf-icon-settings">
                    <ui-dropdown
                        #settingsDropDownMenu
                        [positions]="[
                            {
                                originX: 'end',
                                originY: 'bottom',
                                overlayX: 'end',
                                overlayY: 'top',
                                offsetY: -5
                            }
                        ]">
                        <ui-dropdown-item
                            id="interaction-settings-manage-brands"
                            (click)="goToA2SettingView('brands')">
                            Manage brands
                        </ui-dropdown-item>
                        <ui-dropdown-item
                            id="interaction-settings-manage-users"
                            (click)="goToA2SettingView('users')">
                            Manage users
                        </ui-dropdown-item>
                        <ui-dropdown-item
                            id="interaction-settings-manage-publish-options"
                            *ngIf="hasStudioFeature"
                            (click)="goToA2SettingView('publishoptions')">
                            Manage publish options
                        </ui-dropdown-item>
                        <ui-dropdown-item
                            id="interaction-settings-manage-social-integrations"
                            *ngIf="hasAccessToSocialIntegrations"
                            (click)="goToA2SettingView('socialintegrations')">
                            Manage social integrations
                        </ui-dropdown-item>
                    </ui-dropdown>
                </i>
            </div>
        </div>

        <div
            #profileMenu
            id="interaction-user-menu"
            class="headerContent__actionsUser"
            [uiDropdownTarget]="profileDropDownMenu">
            <div class="headerContent__actionsUserImage">
                <profileImage [showArrow]="true">
                    <ui-dropdown
                        #profileDropDownMenu
                        [positions]="[
                            {
                                originX: 'end',
                                originY: 'bottom',
                                overlayX: 'end',
                                overlayY: 'top',
                                offsetY: -5,
                                offsetX: 15
                            }
                        ]">
                        <ui-dropdown-item
                            id="interaction-user-menu-nav-to-profilesettings"
                            (click)="navigateToProfileSettings()"
                            >Profile settings</ui-dropdown-item
                        >
                        <ui-dropdown-item
                            id="interaction-user-menu-log-out"
                            (click)="logOut()"
                            >Log out</ui-dropdown-item
                        >
                    </ui-dropdown>
                </profileImage>
            </div>
        </div>
    </div>
</div>
