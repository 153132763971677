import { EventEmitter, Injectable, ViewContainerRef } from '@angular/core';
import { SessionService } from './session.service';

@Injectable()
export class AppService {
    public rootViewContainer: ViewContainerRef;
    public settings: AppViewSettings;
    public previousView: any;

    public welcomePanelToggled: EventEmitter<boolean> = new EventEmitter<boolean>();
    public welcomePanelOpened: boolean;

    public isInitialized: boolean;

    constructor(private sessionService: SessionService) {
        this.settings = new AppViewSettings();
    }

    public toggleWelcomePanel(show?: boolean): void {
        // disallow all welcomeMessage operations in one place
        if (!this.isWelcomeMessageFeatureToggleOn()) {
            return;
        }

        // If welcome panel is visible in view
        if (this.settings.welcomePanel) {
            this.welcomePanelOpened = show != null ? show : !this.welcomePanelOpened;
        } else {
            this.welcomePanelOpened = false;
        }

        this.welcomePanelToggled.emit(this.welcomePanelOpened);
    }

    public setIsInitialized(): void {
        this.isInitialized = true;
    }

    public isWelcomeMessageFeatureToggleOn(): boolean {
        return this.sessionService.hasFeatures([SessionService.FEATURES.WELCOMEMESSAGE]);
    }
}

export class AppViewSettings {
    public sidebar = true;
    public welcomePanel = false;
    public brandPicker = true;
}
