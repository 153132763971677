import { Component, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '@core/services/api/azure/environment.service';

@Component({
    selector: 'environment-picker',
    templateUrl: './environment-picker.component.html',
    styleUrl: './environment-picker.component.scss'
})
export class EnvironmentPickerComponent implements OnInit {
    selectedBranch = '';
    pullRequests$: Observable<string[]>;

    private environmentService = inject(EnvironmentService);

    constructor() {
        const url = new URL(window.location.href);
        this.selectedBranch = url.searchParams.get('branch') || 'Main';
    }

    ngOnInit() {
        this.pullRequests$ = this.environmentService.getPullRequests();
    }

    selectedChanged(branch: string): void {
        this.selectedBranch = branch;
        const url = new URL(window.location.href);
        if (branch && branch !== 'Main') {
            url.searchParams.set('branch', branch);
        } else {
            url.searchParams.delete('branch');
        }
        window.location.href = url.href;
    }
}
