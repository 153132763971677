import { Injectable } from '@angular/core';
import { SessionService } from '@core/services/internal/session.service';
import { Brand } from '@shared/models/brand.model';
import { ApiService } from '../api.service';
import { config } from '@config/environment.dev';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class BrandService {
    public activeBrands: Brand[] = [];
    public allBrands: Brand[] = [];
    private simpleBrands: Brand[] = [];

    constructor(private apiService: ApiService, private sessionService: SessionService, private httpClient: HttpClient) {}

    public async getBrands(clearCache?: boolean, isSimpleBrand?: boolean, includeDeleted?: boolean): Promise<Brand[]> {
        if (!clearCache && this.allBrands.length) {
            // Load cache
            if (includeDeleted) {
                return this.allBrands;
            }

            return this.activeBrands;
        }

        if (isSimpleBrand && this.simpleBrands.length) {
            // return simple brands from cache
            return this.simpleBrands;
        }

        const brands: Brand[] = await this.apiCall(isSimpleBrand, includeDeleted);

        if (isSimpleBrand) {
            // cache simple brands
            this.simpleBrands = brands;
        }

        return brands;
    }

    private async apiCall(isSimpleBrand?: boolean, includeDeleted?: boolean): Promise<Brand[]> {
        let allBrands: Brand[] = [];

        try {

            if (isSimpleBrand) {
                allBrands = await this.fetchBrands("current/brands/simple");
            } else {
                allBrands= await this.fetchBrands("current/brands");
            }

            let brands = allBrands.map(brand => {
                return new Brand().deserialize(brand);
            });

            brands = this.sortBrandsByNameAsc(brands);

            if (!isSimpleBrand) {
                // Save cache
                this.allBrands = brands;
                this.activeBrands = this.getActivated(brands);
            }
            if (!includeDeleted) {
                brands = this.getActivated(brands);
            }

            return brands;
        } catch (error) {
            throw error;
        }
    }

    private async fetchBrands(api: string): Promise<Brand[]> {
        const brandSimpleUrl = `${config.origins.ACCOUNT_ACCESS_URL}/api/${this.sessionService.user.account.slug}/${this.sessionService.user.brand.slug}/users/${api}`;

        try {
            const brands: Brand[] = await this.httpClient.get<Brand[]>(brandSimpleUrl).toPromise();

            return brands;
        } catch (error) {
            throw error;
        }
    }

    /**
     * sends a request to BE to toggle brand deletion.
     * If the brand is active, that will result in a brand soft deletion,
     * If the brand is deleted, it will undo that deletion
     * @param brandId Id of the brand to be toggled
     */
    public async activateDeactivatebrand(brandId: string): Promise<number> {
        const url = `/api/v2/a/${this.sessionService.user.account.slug}/brand/${brandId}/state`;

        await this.apiService.put(url, null, { anonymous: true, errorNotification: true });

        let newState: number;
        this.allBrands.forEach(brand => {
            if (brand.id === brandId) {
                newState = brand.state ? 0 : 1;
                brand.state = newState;
            }
        });
        this.activeBrands = this.getActivated(this.allBrands);

        return newState;
    }

    private getActivated(brands: Brand[]): Brand[] {
        const activatedBrands: Brand[] = [...brands.filter(brand => brand.state !== 1)];

        return activatedBrands;
    }

    private sortBrandsByNameAsc(brands: Brand[]): Brand[] {
        return brands.sort((brand1, brand2) => {
            if (brand1.name.toLowerCase() > brand2.name.toLowerCase()) {
                return 1;
            }

            if (brand1.name.toLowerCase() < brand2.name.toLowerCase()) {
                return -1;
            }

            return 0;
        });
    }
}
