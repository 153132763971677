import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

// TODO!! this guy uses moment!!! very expensive
@Pipe({ name: 'toTimeAgo' })
export class ToTimeAgoPipe implements PipeTransform {
    /**
     * Takes a string and returns the moment.fromNow version of that string
     * @param date
     */
    public transform(date: moment.MomentInput): string {
        return moment(date).fromNow();
    }
}
