<div class="wrapper">
    <div class="bfLogo"></div>
    <div class="header-text">Something went wrong</div>
    <div class="content-text">It seems like something didn’t go as expected.</div>

    <ui-button
        type="primary"
        text="Try again"
        (click)="gotoMain()"></ui-button>

    <div class="undertext">
        If you need any assistance,<br />
        please contact <a href="mailto:support@bannerflow.com">support&#64;bannerflow.com</a> <br /><br />
        The Bannerflow team
    </div>
</div>
