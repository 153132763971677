import { HttpClient } from '@angular/common/http';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { IAccountBrandSlug } from '@shared/interfaces/account-brand-slug';

@Component({
    selector: 'redirect',
    templateUrl: 'b2-redirect.component.html'
})
export class B2RedirectComponent implements OnInit {
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private destroyRef = inject(DestroyRef);
    private httpClient = inject(HttpClient);

    public ngOnInit(): void {
        const accountSlug = this.route.snapshot.paramMap.get('accountSlug');
        const brandSlug = this.route.snapshot.paramMap.get('brandSlug');

        if (accountSlug && brandSlug) {
            this.router.navigateByUrl(`/${accountSlug}/${brandSlug}/creative-sets`);
        } else if (AppConfig.config.STAGE === 'production') {
            window.location.href = AppConfig.config.origins.B2_URL;
        } else {
            this.fetchDefaultSlugsAndRedirect();
        }
    }

    private fetchDefaultSlugsAndRedirect(): void {
        this.httpClient
            .get<IAccountBrandSlug>(`${AppConfig.config.origins.ACCOUNT_ACCESS_URL}/api/user/defaults`)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (accountAndBrandSlug: IAccountBrandSlug) => {
                        this.router.navigateByUrl(
                            `/${accountAndBrandSlug.accountSlug}/${accountAndBrandSlug.brandSlug}/creative-sets`
                        );
                },
                error: error => {
                    console.error('Error fetching default account and brand slug:', error);
                }
            });
    }
}
