import { AppConfig } from '@config/app.config';

export function getAuthConfig() {
    return {
        clientId: AppConfig.config.AUTH_CLIENT_ID,
        domain: AppConfig.config.AUTH_DOMAIN,
        authorizationParams: {
            redirect_uri: window.location.search.includes('branch=')
                ? `${window.location.origin}/${window.location.search}`
                : `${window.location.origin}`,
            audience: AppConfig.config.AUTH_AUDIENCE,
            scope: 'bannerflow publishservice campaignservice commentservice studio analyticsbackend socialcampaignservice socialaccountservice profileservice listservice email profile offline_access'
        },
        httpInterceptor: {
            allowedList: Object.values(AppConfig.config.origins).map(origin => ({
                uri: `${origin}/*`,
                tokenOptions: {
                    authorizationParams: {
                        audience: AppConfig.config.AUTH_AUDIENCE
                    }
                }
            }))
        },
        useRefreshTokens: true
    };
}
