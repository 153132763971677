<ui-header
    (logoClick)="onLogoClick()"
    [full]="true">
    <headerContent></headerContent>
</ui-header>

<ng-container *ngIf="stickyNotification$ | async as stickyNotification">
    <ui-sticky-notification
        *ngIf="stickyNotification.shouldShow"
        data-testid="sticky-notification"
        class="sticky-notification"
        [type]="stickyNotification.type">
        <div [ngClass]="stickyNotification.type">
            <ui-loader
                class="sticky-loader"
                [inline]="true"
                *ngIf="stickyNotification.loader"></ui-loader>
            {{ stickyNotification.message }}
        </div>
    </ui-sticky-notification>
</ng-container>
<ui-body [full]="true">
    <div
        class="content-wrapper"
        [ngClass]="{ noSidebar: !appService.settings.sidebar }">
        <sidebar></sidebar>
        <welcomePanel></welcomePanel>
        <div class="content">
            <div
                class="page"
                [ngClass]="isExpanded && 'expandable-margin'">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</ui-body>
