import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import {
    AzureAppConfigFeatureService,
    IAzureAppConfiguration
} from '@core/services/api/azure/azure-app-config-feature.service';
import { NavigatorService } from '@core/services/internal/navigator.service';
import { SessionService } from '@core/services/internal/session.service';
import { User } from '@shared/models/user.model';

@Injectable({
    providedIn: 'root'
})
export class MaintenanceModeGuard implements CanActivate {
    constructor(
        private azAppConfigService: AzureAppConfigFeatureService,
        private sessionService: SessionService,
        private navigatiorService: NavigatorService
    ) {}

    public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        const user: User = await this.sessionService.getUserByActivatedRouteSnapshot(route);
        const maintenanceConfig: IAzureAppConfiguration = route.data.maintenanceModeConfig;

        const isMaintenanceMode: boolean = await this.azAppConfigService.getConfigurationSetting(maintenanceConfig);

        // Make sure user is logged in.
        if (isMaintenanceMode && !user.isEmployee) {
            this.navigatiorService.navigate(['maintenance-mode'], false, {
                label: maintenanceConfig.label,
                key: maintenanceConfig.key
            });

            return false;
        }

        return true;
    }
}
