import { NewRelicOptions } from '@bannerflow/sentinel';
import { AppConfig } from '@config/app.config';

export class NewRelicPlugin {
    public static init(isProduction: boolean, origins: string[]): NewRelicOptions {
        const newRelicConfig = {
            applicationId: isProduction ? '538529219' : '538568885',
            accountId: isProduction ? '4122654' : '4232543',
            agentId: isProduction ? '538529219' : '538568885',
            trustKey: '4122654',
            origins: origins,
            licenseKey: isProduction ? 'NRJS-bd28b6acdc31b77b97c' : 'NRJS-d0b27a9b958bc4b281c',
            applicationVersion: AppConfig.config.BUILD_VERSION,
            releaseName: AppConfig.config.BUILD_VERSION,
            releaseId: AppConfig.config.BUILD_VERSION
        };

        return newRelicConfig;
    }
}
