<div class="content">
    <div class="logo">
        <ui-logo [small]="true"></ui-logo>
    </div>
    <div class="title">
        <h1>We're back soon.</h1>
    </div>
    <div class="text">
        We are really sorry for the inconvenience. We are working as fast as we can to be up and running again shortly.
    </div>
</div>
