import { Injectable } from '@angular/core';
import { RoleEnum } from '@shared/enums/role.enum';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class CampaignManagerFeatureCheckService {
    constructor(private sessionService: SessionService) {}

    public canUseCampaignList(): boolean {
        return this.canUseDisplayCampaigns() || this.canUseSocialCampaigns();
    }

    public canUseDisplayCampaigns(): boolean {
        const { user } = this.sessionService;

        const hasFeature: boolean = this.sessionService.hasFeature(SessionService.FEATURES['CAMPAIGNMANAGER']);

        const hasRole: boolean =
            user.isEmployee ||
            user.role.role === RoleEnum.Admin ||
            user.role.role === RoleEnum.User ||
            user.role.role === RoleEnum.ExternalUser ||
            user.role.role === RoleEnum.UserWithOnlyPublish || // Publisher
            user.role.role === RoleEnum.UserWithoutDesign; // Publisher Extended

        return hasFeature && hasRole;
    }

    public canUseSocialCampaigns(): boolean {
        return this.sessionService.hasFeature(SessionService.FEATURES['SOCIALCAMPAIGNMANAGER']);
    }
}
