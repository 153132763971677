import { initIntercom } from './js/intercom.js';

/**
 * Initializes scripts like gtm, intercom
 */
export class AppScripts {
    public static init(): void {
        initIntercom();
    }
}
