import { inject, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Route,
    RouterStateSnapshot,
    UrlSegment
} from '@angular/router';
import { AuthGuard as Auth0Guard } from '@auth0/auth0-angular';
import { CookieService } from '@core/services/internal/cookie.service';
import { Observable } from 'rxjs';
import { config } from 'src/lib/config/environment.dev';

@Injectable({
    providedIn: 'root'
})
export class ProxyAuthGuard implements CanActivate, CanActivateChild {
    auth0Guard = inject(Auth0Guard);
    public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | boolean {
        if (CookieService.get('IN_TEST') && config.STAGE !== 'production') {
            return true;
        }
        return this.auth0Guard.canLoad(route, segments);
    }
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (CookieService.get('IN_TEST') && config.STAGE !== 'production') {
            return true;
        }
        return this.auth0Guard.canActivate(route, state);
    }
    public canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        if (CookieService.get('IN_TEST') && config.STAGE !== 'production') {
            return true;
        }
        return this.auth0Guard.canActivateChild(childRoute, state);
    }
}
