<div class="container">
    <div class="bfLogo"></div>
    <h1>Please update your browser</h1>
    <div class="text-container">
        <div *ngIf="!isChrome && !isFirefox">
            To access the Bannerflow platform please<br />
            change browser to the latest version of Google Chrome.
        </div>

        <div *ngIf="isChrome">
            To access the Bannerflow platform please<br />
            update your browser to the latest version of Google Chrome.
        </div>

        <div *ngIf="isFirefox">
            Your browser is outdated, <br />
            please use Google Chrome for an optimal experience of the Bannerflow platform
        </div>

        <div>
            Download <a href="https://www.google.com/chrome/">Google Chrome</a><br />
            If you have any questions please <a href="mailto:support@bannerflow.com">contact support</a>
        </div>
    </div>
</div>
