import { Component, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UIThemeService } from '@bannerflow/ui';
import { AppService } from '@core/services/internal/app.service';
import { NavigatorService } from '@core/services/internal/navigator.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    private loading: boolean;

    constructor(
        public appService: AppService,
        public viewContainerRef: ViewContainerRef,
        private activatedRoute: ActivatedRoute,
        private themeService: UIThemeService, // Do not remove since the constructor needs to run to set default theme
        private navigatorService: NavigatorService
    ) {}
}
