import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ListService } from '@modules/creative-set/list-service/list.service';
import { catchError, map, of } from 'rxjs';

export const listRedirectGuard: CanActivateFn = (route, state) => {
    const router = inject(Router);
    const redirectParams = route.queryParams['redirect'];
    if (redirectParams) {
        const listService = inject(ListService);
        const url = state.url.split('?')[0];
        return listService.getFolderIdByCreativeSetId(redirectParams).pipe(
            catchError(error => {
                if (error.status === 404) {
                    router.navigate([url], {
                        queryParams: { folder: 'root' }
                    });
                }
                return of(true);
            }),
            map(folderId => {
                router.navigate([url], {
                    queryParams: { folder: folderId }
                });
                return true;
            })
        );
    }
    return true;
};
